<template>
  <v-container>
    <ConfirmDeleteDialog ref="confirm" />
    <v-card>
      <v-card-title>
        <v-icon large> mdi-card-account-details </v-icon>
        ลูกค้า
        <v-spacer />
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          :to="'/customer/create'"
        >
          <v-icon> mdi-plus </v-icon>
          Add
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-btn
          small
          color="secondary"
          class="ma-2"
          outlined
          @click="exportToExcel()"
        >
          <v-icon> mdi-microsoft-excel </v-icon>
          Export to Excel
        </v-btn>
        <v-data-table
          :headers="headers"
          :items="customers"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 1000] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomers"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.address`]="{ item }">
            <span>
              {{
                `${item.address} ${
                  item.subdistrict.district.province.id == 1 ? "" : "ต."
                }${item.subdistrict.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "อ."
                }${item.subdistrict.district.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "จังหวัด"
                }${item.subdistrict.district.province.name_in_thai} ${
                  item.subdistrict.zip_code
                }`
              }}
            </span>
          </template> -->
          <template v-slot:[`item.customerCRMDatas.length`]="{ item }">
            <span>
              <v-chip
                small
                :color="getColor(item.customerCRMDatas.length)"
                dark
              >
                {{
                  new Intl.NumberFormat("th").format(
                    item.customerCRMDatas.length
                  )
                }}
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.customerSpecialBillDatas.length`]="{ item }">
            <span>
              <v-chip
                small
                :color="getColor(item.customerSpecialBillDatas.length)"
                dark
              >
                {{
                  new Intl.NumberFormat("th").format(
                    item.customerSpecialBillDatas.length
                  )
                }}
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.ictSolutionCustomer`]="{ item }">
            <span v-if="item.ictSolutionCustomer">
              <v-chip class="ma-2" color="success" text-color="white">
                ICT Solution
                <v-icon right> mdi-star </v-icon>
              </v-chip>
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/show'"
            >
              <v-icon> mdi-magnify </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              @click="confirmDeleteCustomer(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
              Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ConfirmDeleteDialog from "../Shared/ConfirmDeleteDialog";

moment.locale("th");

export default {
  name: "CustomerList",
  props: {},
  components: {
    ConfirmDeleteDialog,
  },
  data() {
    return {
      search: "",
      totalCustomers: 0,
      options: {},
      customers: [],
      loading: false,
      headers: [
        {
          text: "ชื่อลูกค้า",
          value: "name",
          sortable: false,
          align: "center",
        },
        // {
        //   text: "รหัสลูกค้า (BA)",
        //   value: "ba_id",
        //   sortable: false,
        //   align: "center",
        // },
        // {
        //   text: "ที่อยู่",
        //   value: "address",
        //   sortable: false,
        //   align: "center",
        // },
        {
          text: "ประเภทลูกค้า",
          value: "customerCategory.name",
          sortable: false,
          align: "center",
        },
        // {
        //   text: "บริการที่ใช้งาน",
        //   value: "customerUseServices.length",
        //   sortable: false,
        //   align: "center",
        // },
        {
          text: "ข้อมูล CRM",
          value: "customerCRMDatas.length",
          sortable: false,
          align: "center",
        },
        {
          text: "ข้อมูลใบแจ้งหนี้พิเศษ",
          value: "customerSpecialBillDatas.length",
          sortable: false,
          align: "center",
        },
        {
          text: "ICT Solution",
          value: "ictSolutionCustomer",
          sortable: false,
          align: "center",
        },
        {
          text: "ศูนย์บริการลูกค้า",
          value: "officeCentre.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนบริการลูกค้า",
          value: "officeCentre.officeSector.name",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customers = response.data.data;
          this.totalCustomers = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportToExcel() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
        },
        responseType: "arraybuffer",
      };
      axios
        .get("/export-to-excel/customer", queryParams)
        .then((response) => {
          // console.log(response);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          // console.log(response.headers);
          link.download = "BCRM_ลูกค้า.xlsx";
          link.click();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    confirmDeleteCustomer: async function (id) {
      if (
        await this.$refs.confirm.open(
          "ยืนยันการลบลูกค้า",
          "คุณแน่ใจที่จะลบลูกค้านี้?"
        )
      ) {
        this.deleteCustomer(id);
      }
    },
    deleteCustomer: async function (id) {
      this.loading = true;
      axios
        .delete("/customer/" + id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.deleteCustomerResponse = response.data;
          this.loading = false;
          if (this.deleteCustomerResponse.status === "success") {
            this.getDataFromAPI();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getColor(number) {
      if (number === 0) return "grey";
      else return "green";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
